import React from 'react'
import styles from './Block.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Tooltip } from 'react-tooltip'

type Props = {
    description?: string,
    helpText?: string,
    number?: string,
    isCurrency?: boolean
}

export function Block(props: Props) {

    return (
        <div className={styles['wrapper']}>
            <div className={styles['number']}>
                {props.isCurrency && '$'}
                {props.number}
            </div>
            <span className={styles['description']}>{props.description}</span>
            {props.helpText && (
                <>
                    <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={props.helpText}
                        className={styles['tooltip']}
                    >
                        <Icon name='info' />
                    </div>
                    <Tooltip id="my-tooltip" className={styles['tooltip-custom']} />
                </>
            )}
        </div >
    );
}

Block.defaultProps = {
    isCurrency: true,
};


