import React from 'react'
import styles from './Request.module.scss'
import { Wrapper } from 'components/Wrapper/Wrapper'
import { WelcomeBlock } from '../../components/WelcomeBlock/WelcomeBlock'
import { Profile } from './components/Profile/Profile'
import { Balance } from './components/Balance/Balance'
import { MoneyTarget } from './components/MoneyTarget/MoneyTarget'
import { Dashboard } from 'components/Dashboard/Dashboard'
import { PopupEditPage } from 'components/PopupEditPage/PopupEditPage'
import { formatNumberWithCommas } from "../../components/helpers";
import { useRequestPageData } from "./useRequestPageData";
import { PreviewSign } from "../../components/PreviewSign/PreviewSign";
import { useIsMobile } from 'hooks/useIsMobile'

const PAGE_TITLE = "Creator Dashboard | Drizzle. Sponsor without spending";
const PAGE_TITLE_MOCK = "Creator Dashboard Preview | Drizzle. Sponsor without spending";

type Props = {
    isPreview?: boolean;
}

export function Request(props: Props) {
    const [showPopup, setShowPopup] = React.useState(false)
    const { isMobile } = useIsMobile()
    const { balance, tvl, totalPatrons, tableData, triggerReload, user } = useRequestPageData(props.isPreview);

    const isBalanceZero = () => { return balance == BigInt(0); }
    const arePatronsZero = () => { return totalPatrons === 0; }

    React.useEffect(() => {
        document.title = props.isPreview ? PAGE_TITLE_MOCK : PAGE_TITLE;
    }, [])

    const formDashboard = () => {
        return [
            {
                id: 'tvl',
                name: 'Total value',
                number: formatNumberWithCommas('', tvl.toString()),
                description: 'Total value deposited',
                helpText: 'Combined amount of money committed to Drizzle in your name. You receive interest from that amount',
            },
            {
                id: 'outstanding',
                name: 'Total outstanding',
                number: formatNumberWithCommas('', balance.toString()),
                description: 'Total outstanding interest',
                helpText: 'The interest that accumulated to date and can be claimed now'
            },
            {
                id: 'patrons',
                name: 'Total patrons',
                number: totalPatrons.toString(),
                description: 'People who sent you drizzles',
                helpText: 'This indicator reflects how loved you are by the crowd',
                isCurrency: false,
            },
            {
                id: 'interest',
                name: 'Current interest rate',
                number: "18.86%",
                description: 'Daily interest (testnet)',
                helpText: 'One can hope',
                isCurrency: false,
            },
        ]
    }

    function openPopupEditPage() {
        setShowPopup(true);
    }

    function closePopupEditPage() {
        setShowPopup(false);
        triggerReload();
    }

    // test data if it no Public
    const isPublic = true

    // test data moneyTarger
    const moneyTarget = 1000

    if (isMobile) {
        return (
            <>
                <div className={styles['mobile']}>
                    <div className={styles['mobile__content']}>
                        <div className={styles['welcome']}>
                            <WelcomeBlock
                                text='Your balance is $0 at the moment. Wait before it starts growing. ' />
                        </div>
                        <Balance
                            amount={balance}
                            isTvlZero={tvl === BigInt(0)}
                            reloadFn={triggerReload}
                            isPreview={props.isPreview}
                        />
                        <Profile
                            isPublic={isPublic}
                            onCustomize={openPopupEditPage}
                            user={user}
                            isPreview={props.isPreview}
                        />
                        {!(tvl === BigInt(0)) && <MoneyTarget amount={moneyTarget} isPreview={props.isPreview} />}
                        <Dashboard title={"Patrons"} data={tableData} analytics={formDashboard()} balance={balance} />
                    </div>
                </div>
                {showPopup && <PopupEditPage onClose={closePopupEditPage} />}
            </>
        )
    }

    return (
        <>
            <Wrapper>
                <div className={styles['container']}>
                    {props.isPreview && <PreviewSign isOnFullPage={true} />}
                    <div className={styles['info']}>
                        <div className={styles['welcome']}>
                            {!arePatronsZero() && isBalanceZero() &&
                                <WelcomeBlock
                                    text={`Your balance is $0 at the moment, but you have patrons! Check back later to see your balance grow. `} />
                            }
                            {arePatronsZero() &&
                                <WelcomeBlock
                                    text={'You don\'t have any patrons at the moment. Would you consider creating a request page?'} />
                            }
                            {!arePatronsZero() && !isBalanceZero() &&
                                <WelcomeBlock
                                    text={`Your balance is ${formatNumberWithCommas('$', balance.toString())} at the moment. Check back later to see it grow! `} />
                            }
                        </div>
                        <div className={styles['content']}>
                            <Profile
                                isPublic={isPublic}
                                onCustomize={openPopupEditPage}
                                user={user}
                                isPreview={props.isPreview}
                            />
                            <div className={styles['balance']}>
                                <Balance
                                    amount={balance}
                                    isTvlZero={tvl === BigInt(0)}
                                    reloadFn={triggerReload}
                                    isPreview={props.isPreview}
                                />
                                {!(tvl === BigInt(0)) && <MoneyTarget amount={moneyTarget} isPreview={props.isPreview} />}
                            </div>
                        </div>
                    </div>
                    <Dashboard title={"Patrons"} data={tableData} analytics={formDashboard()} balance={balance} />
                </div>
            </Wrapper>
            {showPopup && <PopupEditPage onClose={closePopupEditPage} />}
        </>
    )
}

